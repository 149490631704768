import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import ProductGallery from "../components/ProductGallery";
import Header from "../components/Header";
import SliderSimilier from "../components/SliderSimilier";
import Footer from "../components/Footer";
import { httpReauest } from "../utils/httpRequest";
import Loading from "../components/common/Loading";
import { CDN_BASE_URL } from "../config";
import ScrollToTop from "../utils/ScrooltoTop";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const ProductDetailsPage = () => {
  const [productId, setProductId] = useState([]);
  const [loading, setloading] = useState(true);
  const { id } = useParams();
  const [t, i18n] = useTranslation("global");
  async function getId() {
    setloading(true);
    const response = await httpReauest("GET", `/product/${id}`, {}, {});
    setProductId(response.data.data);
    setloading(false);
  }

  useEffect(() => {
    getId();
    window.scrollTo({ top: 0 });
  }, [id]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="">
      <Helmet>
        <title>{productId?.name}</title>
        <meta name="og:title" content={productId?.name} />
        <meta name="description" content={productId?.desc} />
        <meta name="og:description" content={productId?.desc} />
      </Helmet>
      <Header />
      <ScrollToTop />
      <div className=" overflow-hidden mt-5 pt-10  bg-white text-right">
        <div className="lg:grid grid-cols-2  flex flex-col justify-center items-center container">
          <div className=" w-full z-10 ">
            {productId?.images?.length ? (
              <ProductGallery images={productId?.images} img={productId?.img} />
            ) : (
              <img
                src={CDN_BASE_URL + productId?.img}
                className="w-full"
                alt={productId?.name}
              />
            )}
          </div>
          <div className="col-span-1  lg:p-5">
            <h1 className="font-bold text-[18px] lg:text-[27px]   my-2 flex justify-between items-center">
              {productId?.name}
            </h1>
            <div className="flex justify-between my-3 items-center">
              <p>{productId?.desc}</p>
            </div>

            <div className="my-8 flex justify-start">
              <Link className=" bg-[#071777] px-9 py-2 text-[#fffefe] text-[18px]  hover:text-[#071777] duration-100 border-[1px] border-[#071777] hover:bg-transparent">
                {t("get-price")}
              </Link>
            </div>
          </div>
        </div>

        <div
          style={{
            background:
              "linear-gradient(0deg, rgba(7,23,119,1) 0%, rgba(0,202,255,0) 70%)",
          }}
          className="w-full  px-[10px] sm:px-10 py-8 lg:px-20 mt-10"
        >
          <span className="font-bold text-[30px] lg:text-[40px] text-center mb-6 block ">
            {t("popular-products")}
          </span>
          <SliderSimilier category={productId?.category} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProductDetailsPage;
